import { observer } from 'mobx-react';
import React from 'react';
import { Badge, Button, Card, Collapse, ListGroup } from 'react-bootstrap';
import { ShortOrderDto } from 'src/store/OrderStore';
import { dictionary } from 'src/utils/dictionary';
import { parseBonuses, parseDate, parseDiscount, parsePrice } from 'src/utils/formatters';
import grpcService from '../../../services/GrpcService';

interface IProps {
  order: ShortOrderDto;
  expand: (e, pane) => void;
  accordionValue: number;
  stateValue?: number;
  isLast?: boolean;
}

let baseApiProxyUrl;
async function getBaseApiProxyUrl() {
  if (!baseApiProxyUrl) {
    const { url } = await grpcService.PlatformCRMWeb.MetaService.GetCrmWebURL({});
    baseApiProxyUrl = url;
  }

  return baseApiProxyUrl;
}

const OrderInHistory = observer(({ order, expand, accordionValue, stateValue }: IProps) => {
  const [proxyUrl, setProxyUrl] = React.useState();

  React.useEffect(() => {
    getBaseApiProxyUrl().then((url) => setProxyUrl(url));
  });

  const getOptions = () => {
    const orderOptions = order.price?.products?.[0]?.options;
    return orderOptions
      ? orderOptions.map((option) => {
          if (option.totalPrice) {
            return (
              <Badge key={`badge-${option.slug}`} className="mt-1 mr-1" variant={option.slug === 'rooms' ? 'secondary' : 'success'}>
                {option.slug} {option.value}
              </Badge>
            );
          }
          return <></>;
        })
      : undefined;
  };

  const options = getOptions();
  const discount = parseDiscount(order?.price?.totalPrice, order?.price?.subTotalPrice, order?.price?.bonusesUsed);
  const bonuses = parseBonuses(order?.price?.bonusesUsed);
  const totalPrice = parsePrice(order.totalPrice);

  return (
    <Card style={{ borderRadius: 0, borderWidth: '8px' }} className={`border-top-0 border-right-0 border-left-0`}>
      <ListGroup variant="flush">
        <ListGroup.Item className="py-3">
          <div className="d-flex justify-content-between mb-2">
            <h5 className="m-0">
              {dictionary.selectOptions.unionServiceTypes.find((serviceType) => serviceType.id === order.serviceType)?.value || 'Ручной лид'}
            </h5>
            {(order.id && order.orderNumber) ? (
              <a target="_blank" rel="noopener noreferrer" href={`${proxyUrl}/orders/${order.id}/external_admin_url`}>
                {order.orderNumber.toString()}
              </a>
            ) : null}
          </div>
          {order.qleanOrderId && <p>OMS/Монолит № {order.qleanOrderId}</p>}
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-between">
              <Badge className="py-1 px-2" variant="secondary">
                {dictionary.selectOptions.orderStatus.find((status) => status.id === order.status)?.value}
              </Badge>
              &nbsp;
              {order.address?.isPickupPoint ? (
                <Badge className="py-1 px-2" variant="danger">
                  ПВЗ
                </Badge>
              ) : null}
            </div>
            {parseDate(order.startDate, 'dateInTime')}
          </div>
        </ListGroup.Item>
        {/* <ListGroup.Item>
          <div className="text-muted">Подписка</div>
        </ListGroup.Item> */}
        {order.address.value && (
          <ListGroup.Item>
            <div className="text-muted mb-1">Адрес</div>
            <div className="d-flex flex-wrap">{order.address.value}</div>
          </ListGroup.Item>
        )}
        {stateValue !== accordionValue && (
          <ListGroup.Item className="align-self-center cursor-pointer w-100">
            <Button className="p-0 w-100" variant="link" onClick={(e) => expand(e, accordionValue)}>
              Подробнее
            </Button>
          </ListGroup.Item>
        )}
      </ListGroup>
      <Collapse in={stateValue === accordionValue}>
        <ListGroup variant="flush">
          {options && (
            <ListGroup.Item>
              <div className="text-muted mb-1">Опции</div>
              <div className="d-flex flex-wrap">{options}</div>
            </ListGroup.Item>
          )}
          {order.promocode && (
            <ListGroup.Item className="d-flex justify-content-between">
              <div className="text-muted">Промокод</div>
              <div>{order.promocode}</div>
            </ListGroup.Item>
          )}
          {discount && (
            <ListGroup.Item className="d-flex justify-content-between">
              <div className="text-muted">Скидка</div>
              <div>{discount}</div>
            </ListGroup.Item>
          )}
          {bonuses && (
            <ListGroup.Item className="d-flex justify-content-between">
              <div className="text-muted">Бонусные баллы</div>
              <div>{bonuses}</div>
            </ListGroup.Item>
          )}
          {totalPrice && (
            <ListGroup.Item className="d-flex justify-content-between">
              <div className="text-muted">Итого</div>
              <h5 className="m-0">{totalPrice}</h5>
            </ListGroup.Item>
          )}
          <ListGroup.Item className="align-self-center cursor-pointer w-100">
            <Button className="p-0 w-100" variant="link" onClick={(e) => expand(e, accordionValue)}>
              Свернуть
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Collapse>
    </Card>
  );
});

export default OrderInHistory;
