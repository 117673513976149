import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import {Card, Col, Row} from 'react-bootstrap';

import { FIELDS } from 'src/components/Forms/form.interface';
import DynamicForm from 'src/components/Forms/DynamicForm';
import {
  B2BServices,
  IPaymentType,
  ServiceType,
  dictionary,
  getTimeslotsSource,
  labsServiceTypes,
  lmsServiceTypes,
  serviceTypesWithContractorPaymentTypes,
  INumberOfPayments,
  getPaymentType,
  getPaymentTypesForService, isLeaveAtTheDoorAvailable,
} from 'src/utils/dictionary';

import 'src/vendor/styles/pages/users.scss';
import OrderStore, { OrderDto } from 'src/store/OrderStore';
import CustomerStore from 'src/store/CustomerStore';
import { ITimeSlotValue } from 'src/components/Fields/TimeSlot/TimeSlotField';
import { CalculateOptionDto } from "../../store/ProspectStore";
import metaStore from '../../store/MetaStore';
import moment from "moment";

interface IProps {
  order: OrderDto;
  onOrderDataSubmit: (values) => void;
}

const OrderData = observer(({ order, onOrderDataSubmit }: IProps) => {
  const { isOMSOrder, disabledSlots, setDisabledSlots } = OrderStore;
  const { platformCards } = metaStore;
  const { attachCard } = CustomerStore;

  const [timeSlotValue, setTimeSlotValue] = useState<ITimeSlotValue>({
    date: order?.startDate ? new Date(order?.startDate).toISOString().substring(0, 10) : undefined,
    startDate: order.startDate,
  });
  const [numberOfPayments, setNumberOfPayments] = useState<INumberOfPayments[]>([]);

  useEffect(() => {
    metaStore.getPlatformCards({userId: order.person.ssoId});
  }, [order.person.ssoId])

  useEffect(() => {
    const product = order?.price?.products?.[0];
    const deliveryOption: CalculateOptionDto | undefined = (product?.options || []).find((option) => option.slug === 'delivery');
    const isB2BService: boolean = B2BServices.includes(product?.productSlug);

    if (!deliveryOption && isB2BService) {
      setDisabledSlots(true);
      if (!order.startDate) {
        setTimeSlotValue({
          date: moment().toISOString(),
          startDate: moment().toISOString(),
        })
      }
    } else {
      setDisabledSlots(false);
    }
  }, [OrderStore.order?.price?.products?.[0]?.options?.length]);

  useEffect(() => {
    if (serviceTypesWithContractorPaymentTypes.includes(order.serviceType)) {
      setNumberOfPayments([{id: '2', value: 2}, {id: '4', value: 4}]);
    }
  }, [order.id]);

  const getPayments = (): IPaymentType[] => {
    let items = getPaymentTypesForService(order.serviceType) ||  [getPaymentType(order.serviceType)]

    if (serviceTypesWithContractorPaymentTypes.includes(order.serviceType)) {
      items.push(
        {
          id: 'installment_plan',
          value: 'Рассрочка',
        },
        {
          id: 'contractor_main_card',
          value: 'Картой исполнителя',
        }
      );
    }

    if (lmsServiceTypes.includes(order.serviceType) && !serviceTypesWithContractorPaymentTypes.includes(order.serviceType)) {
      for (const card of platformCards || []) {
        items.push({
          id: 'credit_card',
          value: `Карта ${card.cardNumber}`,
          meta: card.cardId + '',
        });
      }
    }

    if (order.leaveAtTheDoor) {
      items = items.filter(pt => pt.id !== 'soft_pos')
    }

    return items
  }

  const onButtonClick = () => {
    if (order.paymentType === 'credit_card') {
      if (!isOMSOrder && order?.person?.id) {
        attachCard({ personId: order.person.id });
      }
    }
  };

  const onClickSubmit = (values) => {
    const { id } = order;
    const startDate = values?.timeslot?.startDate?.toISOString() || '';
    setTimeSlotValue({ date: values?.timeslot?.date, startDate });
    if (values.serviceType) {
      const formValues = {
        ...values,
        id,
        // serviceType: values.serviceType.id,
        paymentType: values?.paymentType?.id,
        paymentTypeMeta: values?.paymentType?.meta,
        startDate: startDate || order.startDate,
        regionId: values?.addressWithRegion?.value?.regionId,
        address: values?.addressWithRegion?.value?.address || {},
        discount: values?.discount?.discount,
        promocode: values?.discount?.promocode,
      }

      onOrderDataSubmit(formValues);
    }
  };

  const getInitialPaymentType = () => {
    let paymentType;
    if (order.serviceType === ServiceType.SellGoodsNew && order.paymentType === 'credit_card') {
      paymentType = getPayments().find((type) => type.meta === order.paymentTypeMeta);
      if (paymentType) { return paymentType; }
      return;
    }
    paymentType = getPayments().find((type) => type.id === order.paymentType);
    if (paymentType) {
      return paymentType;
    } else {
      if (!labsServiceTypes.includes(order.serviceType) && order.person.qleanCreditCardNumber) {
        return { id: 'credit_card', value: `Карта ${order.person.qleanCreditCardNumber}` };
      } else {
        return getPayments()[0];
      }
    }
  };

  const getNumberOfPayments = () => {
    if (order.numberOfPayments) {
      return numberOfPayments?.find(({value}) => value === order.numberOfPayments);
    }

    return 0;
  };

  const dynamicForm = {
    name: 'order-edit',
    rows: [
      [
        {
          type: FIELDS.SELECT,
          name: 'serviceType',
          label: {
            label: 'Продукт',
          },
          placeholder: 'Выберите продукт',
          optionValueKey: 'id',
          optionLabelKey: 'value',
          options: dictionary.selectOptions.unionServiceTypes,
          variant: 'dropdown',
          isReadonly: true,
          initialValue: dictionary.selectOptions.unionServiceTypes.find((type) => type.id === order.serviceType),
        },
      ],
      [
        {
          type: FIELDS.ADDRESS,
          name: 'addressWithRegion',
          label: {
            label: 'Адрес',
          },
          isReadonly: true,
          placeholder: 'Введите адрес',
          initialValue: {
            value: { address: order?.address, regionId: order.regionId },
            isShortForm: false,
            isCannotBeChanged: true,
          },
          withRegion: true,
        },
      ],
      [
        {
          type: FIELDS.TIMESLOT,
          name: 'timeslot',
          initialValue: timeSlotValue,
          serviceType: order.serviceType,
          prospectId: order.prospectId,
          isReadonly: !labsServiceTypes.includes(order.serviceType) || disabledSlots,
          timeslotsSource: getTimeslotsSource({ paymentType: order.paymentType, serviceType: order.serviceType }) ,
        },
      ],
      [
        {
          type: FIELDS.PAYMENT_TYPE,
          name: 'paymentType',
          label: {
            label: 'Тип оплаты',
          },
          placeholder: 'Выберите тип оплаты',
          options:
            !labsServiceTypes.includes(order.serviceType) && order.person.qleanCreditCardNumber
              ? [
                  ...getPayments(),
                  {
                    id: 'credit_card',
                    value: `Карта ${order.person.qleanCreditCardNumber}`,
                  },
                ]
              : getPayments(),
          initialValue: getInitialPaymentType(),
          buttonTitle: labsServiceTypes.includes(order.serviceType)
            ? undefined
            : getPayments().find((paymentType) => paymentType.id === order.paymentType)?.button,
          onButtonClick,
          isReadonly: !labsServiceTypes.includes(order.serviceType),
        },
        {
          type: FIELDS.NUMBER_OF_PAYMENTS,
          name: 'numberOfPayments',
          label: {
            label: 'Кол-во платежей',
          },
          placeholder: 'Выберите кол-во платежей',
          options: numberOfPayments,
          initialValue: getNumberOfPayments(),
          isReadonly: true,
        },
        {
          type: FIELDS.DISCOUNT_WITH_PROMOCODE,
          name: 'discount',
          initialValue: {
            promocode: order?.promocode,
            discount: dictionary.selectOptions.discounts.find((discount) => discount.promocode === order.promocode),
          },
          disabled: !Boolean(order.price),
          options: dictionary.selectOptions.discounts,
          isReadonly: !labsServiceTypes.includes(order.serviceType),
        },
      ],
      // [
      //   {
      //     type: FIELDS.SELECT,
      //     name: 'cleaner',
      //     label: {
      //       label: 'Исполнитель',
      //     },
      //     placeholder: 'Выберите исполнителя',
      //     optionValueKey: 'id',
      //     optionLabelKey: 'value',
      //     options: dictionary.selectOptions.cleaners,
      //     variant: 'dropdown',
      //     initialValue: order?.executorId,
      //   },
      // ],
      // [
      // ],
      [
        {
          type: FIELDS.STRING,
          name: 'commentForSupport',
          label: {
            label: 'Комментарий к заказу для поддержки',
          },
          placeholder: 'Введите комментарий к заказу для поддержки',
          initialValue: order.commentForSupport,
          variant: 'textarea',
          isReadonly: !labsServiceTypes.includes(order.serviceType),
        },
        {
          type: FIELDS.STRING,
          name: 'commentForExecutor',
          label: {
            label: 'Комментарий к заказу для исполнителя',
          },
          placeholder: 'Введите комментарий к заказу для исполнителя',
          initialValue: order.commentForExecutor,
          variant: 'textarea',
          isReadonly: !labsServiceTypes.includes(order.serviceType),
        },
      ],
      isLeaveAtTheDoorAvailable(order.serviceType, order.price?.products) ? [
        {
          type: FIELDS.BOOLEAN,
          name: 'leaveAtTheDoor',
          label: {
            label: "Оставить у двери",
          },
          placeholder: "",
          initialValue: order.leaveAtTheDoor ?? false,
          disabled: true,
          isReadonly: true
        }
      ] : []
    ],
  };

  return (
    <Card className="mb-4">
      <Card.Header>
        <Col>
          <Row as="h6" className="justify-content-between align-items-center my-1">
            <span>Заказ</span>
            <div>
              <span>№ {order.orderNumber}</span>
            </div>
          </Row>
          {order.qleanOrderId && (
            <Row as="p" className="justify-content-end mb-0">
              <div>OMS/Монолит № {order.qleanOrderId}</div>
            </Row>
          )}
        </Col>
      </Card.Header>
      <Card.Body>
        {
          // @ts-ignore
        }
        <DynamicForm form={dynamicForm} onSubmit={onClickSubmit} isAutoSubmit />
      </Card.Body>
    </Card>
  );
});

export default OrderData;
