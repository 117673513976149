import { action, observable, runInAction } from 'mobx';
import { plainToClass } from 'class-transformer';
import { logger } from '@qlean/front-logger';

import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService';
import { PriceElementDto } from './MetaStore.dto';
import { dictionary } from '../../utils/dictionary';
import { OrderIssueDto } from '../OrderStore';
import { toast } from 'react-toastify';

interface IDiscount {
  value: string;
  id: number;
  promocode: string;
}

export default class MetaStore {
  @observable isLoading: boolean = false;
  @observable priceElement?: PriceElementDto;
  @observable dryCleaningSlots?: PlatformCRMWeb.IDrycleaningSlot[];
  @observable dryCleaningCards?: PlatformCRMWeb.IDrycleaningCard[];
  @observable platformCards?: PlatformCRMWeb.IPlatformCard[];
  @observable dryCleaningSlotsLoading: boolean = false;
  @observable offers: IDiscount[] = dictionary.selectOptions.discounts;

  @action getAvailableDates = (args?: PlatformCRMWeb.GetAvailableDatesRequest): Promise<string[]> => {
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.MetaService.GetAvailableDates(req)
      .then((res) => {
        const { dates } = res;
        return dates;
      })
      .catch((e) => {
        logger.error(`[MetaStore::getAvailableDates]`, e);
        return [];
      });
  };

  @action getAvailableTimeSlots = (args?: PlatformCRMWeb.GetAvailableTimeSlotsRequest): Promise<string[]> => {
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.MetaService.GetAvailableTimeSlots(req)
      .then((res) => {
        const { slots } = res;
        return slots;
      })
      .catch((e) => {
        logger.error(`[MetaStore::getAvailableTimeSlots]`, e);
        return [];
      });
  };

  @action getAvailableLmsTimeSlotsByCoordinates = (args?: { prospectId: string }): Promise<string[]> => {
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.MetaService.GetAvailableTimeSlotsFromLmsByCoordinates(req)
      .then((res) => {
        const { slots } = res;

        return slots;
      })
      .catch((e) => {
        logger.error(`[MetaStore::getAvailableLmsTimeSlotsByCoordinates]`, e);
        return [];
      });
  };

  @action searchPromocode = (args?: PlatformCRMWeb.ISearchPromocodeRequest) => {
    const req = { ...args };
    logger.debug('[MetaStore::searchPromocodes request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.SearchPromocode(req)
      .then((res) => {
        logger.debug('[MetaStore::searchPromocodes response]', res);
        return res.promocodes;
      })
      .catch((e) => {
        logger.error(`[MetaStore::searchPromocodes]`, e);
        return [];
      });
  };

  @action getDryCleaningSlots = (args?: PlatformCRMWeb.IGetDrycleaningTimeslotsRequest) => {
    const req = { ...args };
    this.dryCleaningSlotsLoading = true;

    logger.debug('[MetaStore::getDryCleaningSlots request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.GetDrycleaningTimeslots(req)
      .then((res) => {
        logger.debug('[MetaStore::getDryCleaningSlots response]', res);
        this.dryCleaningSlots = res.data;
        this.dryCleaningSlotsLoading = false;
      })
      .catch((e) => {
        this.dryCleaningSlots = [];
        this.dryCleaningSlotsLoading = false;
        logger.error(`[MetaStore::getDryCleaningSlots]`, e);
        return [];
      });
  };

  @action getDryCleaningCards = (args?: PlatformCRMWeb.IGetDrycleaningCardsRequest) => {
    const req = { ...args };

    logger.debug('[MetaStore::GetDrycleaningCards request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.GetDrycleaningCards(req)
      .then((res) => {
        logger.debug('[MetaStore::GetDrycleaningCards response]', res);
        this.dryCleaningCards = res.data;
      })
      .catch((e) => {
        this.dryCleaningCards = [];
        logger.error(`[MetaStore::GetDrycleaningCards]`, e);
        return [];
      });
  };

  @action getPlatformCards = (args?: PlatformCRMWeb.IGetPlatformCardsRequest) => {
    const req = { ...args };

    logger.debug('[MetaStore::getPlatformCards request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.GetPlatformCards(req)
      .then((res) => {
        logger.debug('[MetaStore::getPlatformCards response]', res);
        this.platformCards = res.data;
      })
      .catch((e) => {
        this.platformCards = [];
        logger.error(`[MetaStore::getPlatformCards]`, e);
        return [];
      });
  };

  @action getOffers = (args?: PlatformCRMWeb.IGetOffersRequest) => {
    const req = { ...args };

    logger.debug('[MetaStore::getOffers request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.GetOffers(req)
      .then((res) => {
        runInAction(() => {
          logger.debug('[MetaStore::getOffers response]', res);

          if (res.data.length === 0) {
            logger.debug('[MetaStore::getOffers use default ofers]', res);
            this.offers = [];
            return;
          }

          this.offers = res.data.map((item, i) => ({
            id: i + 1,
            promocode: item.promocode || '',
            value: item.title || '',
          }));
        });
      })
      .catch((e) => {
        this.offers = [];
        logger.error(`[MetaStore::getOffers]`, e);
        return [];
      });
  };

  @action issueItems = (args?: PlatformCRMWeb.IssueWarehouseItemsRequest) => {
    this.isLoading = true;
    const req = { ...args };

    logger.debug('[MetaStore::issueItems request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.IssueWarehouseItems(req)
      .then((res) => {
        runInAction(() => {
          logger.debug('[MetaStore::issueItems response]', res);
        });
        this.isLoading = false;
        toast('Груз выдан', { type: 'success', autoClose: 1500 })
        return res.issue as OrderIssueDto
      })
      .catch((e) => {
        this.isLoading = false;
        logger.error('[MetaStore::issueItems error]', e);
        toast(`Ошибка: ${e.message}`, { type: 'error', autoClose: 2500 });
        return null;
      });
  }

  @action undoIssue = (args?: PlatformCRMWeb.UndoOrderIssueRequest) => {
    this.isLoading = true;
    const req = { ...args };

    logger.debug('[MetaStore::undoIssue request]', args);
    return GrpcService.PlatformCRMWeb.MetaService.UndoOrderIssue(req)
      .then((res) => {
        runInAction(() => {
          logger.debug('[MetaStore::undoIssue response]', res);
          this.isLoading = false;
        });
        toast('Груз возвращен', { type: 'success', autoClose: 1500 })
      })
      .catch((e) => {
        logger.error('[MetaStore::undoIssue error]', e);
        this.isLoading = false;
        toast(`Ошибка: ${e.message}`, { type: 'error', autoClose: 2500 });
      });
  }

  @action setOffers(args: IDiscount[]) {
    this.offers = args;
  }

  @action getPriceElement = (args?: PlatformCRMWeb.GetPriceElementRequest) => {
    this.isLoading = true;
    const req = { ...args };
    logger.debug('[getPriceElement] request', args);

    GrpcService.PlatformCRMWeb.MetaService.GetPriceElement(req)
      .then((res) => {
        runInAction(() => {
          const { priceElement } = res;
          this.priceElement = plainToClass(PriceElementDto, priceElement);
          logger.debug('[getPriceElement]', priceElement, this.priceElement);
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error(`[MetaStore::getPriceElement]`, e);
        this.isLoading = false;
      });
  };
}
