import { observer } from 'mobx-react';
import React from 'react';
import { Modal } from 'react-bootstrap';

import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS } from 'src/components/Forms/form.interface';
import CommunicationStore from 'src/store/CommunicationStore';
import { formatPhone } from 'src/utils/formatters';

interface IProps {
  isShowModal: boolean;
  onCloseModal: () => void;
  phoneNumber?: string;
  orderDate: string | undefined;
  orderPrice: number;
  verifyContractor: () => void;
  toggleVerification: () => void;
  isToggledVerification: boolean;
}

export const VerificationContractorModal = observer(
  ({ isShowModal, onCloseModal, phoneNumber, toggleVerification, isToggledVerification, verifyContractor, orderPrice, orderDate }: IProps) => {
    const { isActionProcessing, contractorVerificationStart, contractorVerificationCheck, errors } = CommunicationStore;

    const onSubmitStart = async (values) => {
      const phone = formatPhone(values?.phone || '', true);

      orderDate = !!orderDate ? orderDate : new Date().toISOString();

      await contractorVerificationStart({
        phone: `7${phone}`,
        orderPrice,
        orderDate,
      });

      toggleVerification();
    };

    const onSubmitVerify = async (values) => {
      const phone = phoneNumber;
      const code = formatPhone(values?.code || '', true);

      await contractorVerificationCheck({
        phone,
        code,
      });

      toggleVerification();
      verifyContractor();
      onCloseModal();
    };

    const startFormProps = {
      name: 'contractor-verification',
      rows: [
        [
          {
            type: FIELDS.MOBILE_PHONE,
            name: 'phone',
            initialValue: phoneNumber,
            hideCopyButton: true,
            label: {
              label: 'Телефон клиента',
            },
            placeholder: 'Введите телефон клиента',
            errors: errors.find((error) => error.type === 'phone')?.message,
            disabled: true,
          },
        ],
      ],
    };

    const checkFormProps = {
      name: 'contractor-verification',
      rows: [
        [
          {
            type: FIELDS.NUMBER,
            name: 'code',
            initialValue: '',
            hideCopyButton: true,
            label: {
              label: 'Код верификации',
            },
            placeholder: 'Введите код',
            errors: errors.find((error) => error.type === 'code')?.message,
          },
        ],
      ],
    };

    return (
      <Modal show={isShowModal} size="sm" onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Необходима верификация клиента</Modal.Title>
        </Modal.Header>
        <Modal.Body hidden={isToggledVerification}>
          <DynamicForm
            form={startFormProps}
            onCancel={onCloseModal}
            onSubmit={onSubmitStart}
            submitText="Отправить код"
            isLoading={isActionProcessing}
          />
        </Modal.Body>

        <Modal.Body hidden={!isToggledVerification}>
          <DynamicForm form={checkFormProps} onCancel={onCloseModal} onSubmit={onSubmitVerify} submitText="Верифицировать" />
        </Modal.Body>
      </Modal>
    );
  }
);
