import { observer } from 'mobx-react';
import { ProspectDto } from '../../store/ProspectStore';
import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { dictionary } from '../../utils/dictionary';
import { format } from 'date-fns';
import { ButtonTitle } from '../ButtonTitle';

type Props = {
  prospect: ProspectDto;
  isShown: boolean;
  onAccept: () => unknown | Promise<unknown>;
  onCancel: () => unknown | Promise<unknown>;
  isLoading: boolean;
}

export const ConfirmApplyOrderModal = observer<React.FC<Props>>(({ prospect, onAccept, onCancel, isShown, isLoading }) => {
  if (!prospect.startDate || !prospect.price) {
    return null;
  }

  const totalDiscount = Math.round(
    ((prospect.price.subTotalPrice - prospect.price.totalPrice) / prospect.price.subTotalPrice) * 100)

  return (
    <Modal show={isShown}>
      <Modal.Header>
        <Modal.Title>Подтвердите оформление заказа</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col className="font-weight-semibold">Тариф</Col>
            <Col>{dictionary.selectOptions.unionServiceTypes.find(st => st.id === prospect.serviceType)?.value}</Col>
          </Row>
          <Row>
            <Col className="font-weight-semibold">Дата/время забора</Col>
            <Col>{format(new Date(prospect.startDate), 'yyyy-MM-dd HH:mm')}</Col>
          </Row>
          {prospect.promocode ?
            (
              <Row>
                <Col className="font-weight-semibold">Скидка</Col>
                <Col>
                  {Number.isNaN(totalDiscount) ? 0 : totalDiscount}% / {prospect.promocode}
                </Col>
              </Row>
            ) : null
          }
          <Row>
            <Col className="font-weight-semibold">Адрес</Col>
            <Col>{prospect.address.value}</Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex row justify-content-between px-10">
        <Button disabled={isLoading} variant="success" onClick={onAccept}>
          <ButtonTitle isLoading={isLoading} title="Оформить" />
        </Button>
        <Button disabled={isLoading} variant="outline-dark" onClick={onCancel}>
          <ButtonTitle isLoading={isLoading} title="Отмена" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
})
