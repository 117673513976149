import { action, observable, runInAction } from 'mobx';
import { plainToClass } from 'class-transformer';

import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService';
import { logger } from '@qlean/front-logger';
import {
  ExecutorDto,
  GetExecutorsListResponseDto,
  GetOrderByIdResponseDto,
  OrderDto, OrderIssueDto,
  RejectionGroupDto,
  SearchOrdersResponseDto,
  ShortOrderDto,
} from './OrderStore.dto';
import { CalculatePriceDto } from '../ProspectStore';
import { ServiceType } from '../../utils/dictionary';

export default class OrderStore {
  @observable isLoading: boolean = false;
  @observable order?: OrderDto;
  @observable allExecutors?: ExecutorDto[];
  @observable orderExecutors?: ExecutorDto[];
  @observable disabledSlots: boolean = false;
  @observable rejectionReasons: { isLoading: boolean; groups: RejectionGroupDto[] } = {
    groups: [],
    isLoading: false,
  };

  @observable shortOrders: { isLoading: boolean; orders: ShortOrderDto[] } = {
    isLoading: false,
    orders: [],
  };

  @observable orderFormValues = {};

  @action setDisabledSlots = (state: boolean): void => {
    this.disabledSlots = state;
  };

  get isOMSOrder(): boolean {
    return (
      this.order?.serviceType === 'sell_goods_new' ||
      (this.order?.serviceType === 'sell_goods_chemistry' && (!this.order?.paymentType || this.order?.paymentType !== 'sms'))
    );
  }

  get orderFormHasChanged(): boolean {
    return !!Object.keys(this.orderFormValues).length;
  }

  @action getOrderById = (args: PlatformCRMWeb.GetOrderByIdRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.OrderService.GetOrderById(req)
      .then((res) => {
        const { order, executors } = plainToClass(GetOrderByIdResponseDto, res);
        runInAction(() => {
          this.order = order;
          this.orderExecutors = executors;
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[OrderStore::getOrderById]', e);
        this.isLoading = false;
      });
  };

  @action repeatPaymentInOmsOrder = () => {
    this.isLoading = true;
    GrpcService.PlatformCRMWeb.OrderService.UpdatePaymentTypeInOmsOrder({
      orderId: this.order!.id,
      paymentType: this.order?.paymentType,
      paymentTypeMeta: this.order?.paymentTypeMeta,
    })
      .then((res) => {
        const { paymentStatus } = res;
        if (this.order) {
          this.order = {
            ...this.order,
            paymentStatus,
          };
        }
        this.isLoading = false;
      })
      .catch((e) => {
        if (this.order) { this.getOrderById({ id: this.order.id }); }
        logger.error('[OrderStore::updateOmsOrder]', e);
      });
  };

  @action updatePaymentTypeInOmsOrder = (args: { paymentType?: string; paymentTypeMeta?: string }) => {
    if (this.order?.serviceType === ServiceType.SellGoodsNew) {
      if (this.order?.paymentType !== args.paymentType || this.order?.paymentTypeMeta !== args.paymentTypeMeta) {
        this.isLoading = true;
        GrpcService.PlatformCRMWeb.OrderService.UpdatePaymentTypeInOmsOrder({ orderId: this.order.id, ...args })
          .then((res) => {
            const { paymentStatus } = res;
            const { paymentType, paymentTypeMeta } = args;
            if (this.order) {
              this.order = {
                ...this.order,
                paymentType: paymentType || this.order.paymentType,
                paymentTypeMeta: paymentTypeMeta || this.order.paymentTypeMeta,
                paymentStatus,
              };
            }
            this.isLoading = false;
          })
          .catch((e) => {
            if (this.order) { this.getOrderById({ id: this.order.id }); }
            logger.error('[OrderStore::updateOmsOrder]', e);
          });
      }
    }
  };

  @action updateOrder = (args: PlatformCRMWeb.IUpdateOrderRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.OrderService.Update(req)
      .then((res) => {
        const { totalDuration, totalPrice, isOvertime, price } = res;
        const { regionId, discount, startDate, paymentType, commentForSupport, commentForExecutor, promocode } = req;
        if (this.order) {
          this.order = {
            ...this.order,
            regionId: regionId || this.order.regionId,
            discount: discount || this.order.discount,
            startDate: startDate || this.order.startDate,
            paymentType: paymentType || this.order.paymentType,
            paymentTypeMeta: this.order.paymentTypeMeta,
            commentForSupport: commentForSupport || this.order.commentForSupport,
            commentForExecutor: commentForExecutor || this.order.commentForExecutor,
            promocode: promocode || this.order.promocode,
            totalDuration,
            totalPrice,
            isOvertime,
            price: plainToClass(CalculatePriceDto, price),
          };
        }
        this.isLoading = false;
      })
      .catch((e) => {
        logger.error('[OrderStore::updateOrder]', e);
        this.isLoading = false;
      });
  };

  @action getExecutorsList = (args: PlatformCRMWeb.IGetExecutorsListRequest) => {
    this.isLoading = true;
    this.allExecutors = [];
    const req = { ...args };
    GrpcService.PlatformCRMWeb.OrderService.ExecutorsList(req)
      .then((res) => {
        runInAction(() => {
          const { executors } = plainToClass(GetExecutorsListResponseDto, res);
          this.allExecutors = executors;
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[OrderStore::getExecutorsList]', e);
        this.isLoading = false;
      });
  };

  @action assignExecutor = (args: PlatformCRMWeb.IAssignExecutorRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.OrderService.AssignExecutor(req)
      .then((res) => {
        runInAction(() => {
          const { executors } = plainToClass(GetExecutorsListResponseDto, res);
          this.orderExecutors = executors;
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[OrderStore::assignExecutor]', e);
        this.isLoading = false;
      });
  };

  @action unassignExecutor = (args: PlatformCRMWeb.IUnassignExecutorRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.OrderService.UnassignExecutor(req)
      .then((res) => {
        runInAction(() => {
          const { executors } = plainToClass(GetExecutorsListResponseDto, res);
          this.orderExecutors = executors;
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[OrderStore::unassignExecutor]', e);
        this.isLoading = false;
      });
  };

  @action sendInvoice = (args: PlatformCRMWeb.SendInvoiceRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.OrderService.SendInvoice(req)
      .then(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[OrderStore::sendInvoice]', e);
        this.isLoading = false;
      });
  };

  @action sendPaymentLink = (args: PlatformCRMWeb.SendPaymentLinkRequest): Promise<boolean> => {
    this.isLoading = true;
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.OrderService.SendPaymentLink(req)
      .then(() => {
        runInAction(() => {
          this.isLoading = false;
        });
        return true;
      })
      .catch((e) => {
        logger.error('[OrderStore::sendPaymentLink]', e);
        this.isLoading = false;
        return false;
      });
  };

  @action changeStatus = (args: PlatformCRMWeb.IOrderChangeStatusRequest) => {
    this.isLoading = true;
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.OrderService.ChangeStatus(req)
      .then((res) => {
        runInAction(() => {
          this.isLoading = false;
          if (this.order) {
            this.order = {
              ...this.order,
              status: req.status || this.order?.status,
              cancelReason: req.cancelReason || this.order?.cancelReason,
            };
          }
        });
        return { success: true, message: res?.message || 'Статус успешно изменён' };
      })
      .catch((e) => {
        logger.error('[OrderStore::changeStatus]', e);
        this.isLoading = false;
        return { success: false, message: e?.message || 'Ошибка изменения статуса' };
      });
  };

  @action searchOrders = (args?: PlatformCRMWeb.ISearchOrdersRequest) => {
    this.shortOrders.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.OrderService.SearchOrders(req)
      .then((res) => {
        const { data } = plainToClass(SearchOrdersResponseDto, res);
        this.shortOrders = {
          isLoading: false,
          orders: data,
        };
        logger.debug('[CustomerStore::searchOrders] ok', { orders: data });
      })
      .catch((e) => {
        logger.error('[CustomerStore::searchOrders]', e);
        this.shortOrders.isLoading = false;
      });
  };

  @action getOrderRejectionGroups = async (args?: PlatformCRMWeb.IRejectionGroupsRequest) => {
    this.rejectionReasons.isLoading = true;
    const req = { ...args };

    try {
      const { data } = await GrpcService.PlatformCRMWeb.OrderService.SearchRejectionGroups(req);

      function convertData(originalData: PlatformCRMWeb.IRejectionGroup[], parentId: string | null = ''): RejectionGroupDto[] {
        const filteredItems = originalData.filter((item) => item.parentGroupId === parentId);

        return filteredItems.map((item) => {
          const newItem: RejectionGroupDto = {
            id: item.id!,
            parentGroupId: item.parentGroupId!,
            type: item.type!,
            reasons: (item.reasons || [])?.map((r) => ({ id: r.id!, slug: r.slug!, title: r.title! })),
            title: item.title!,
          };

          const subGroups = convertData(originalData, item.id);
          if (subGroups.length > 0) {
            newItem.groups = subGroups;
          }

          return newItem;
        });
      }

      this.rejectionReasons.groups = convertData(data);

      logger.debug('[CustomerStore::getOrderRejectionGroups] ok', { groups: this.rejectionReasons.groups });
    } catch (err) {
      logger.error('[OrderStore::getOrderRejectionGroups]', err);
    } finally {
      this.rejectionReasons.isLoading = false;
    }
  };

  @action payBySoftPos = async (): Promise<string | undefined> => {
    this.isLoading = true;

    try {
      if (!this.order?.qleanOrderId) {
        return;
      }

      const resp = await GrpcService.PlatformCRMWeb.FinanceService.PayForOrder({
        serialNumber: Number(this.order.qleanOrderId),
      })

      logger.debug('[OrderStore::payBySoftPos] ok', { orderId: this.order?.id });

      return resp.activeProcess?.link ?? undefined;
    } catch (err) {
      logger.error('[OrderStore::payBySoftPos] fail', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action upsertIssue(issue: OrderIssueDto) {
    if (!this.order?.issues) {
      return;
    }

    const issueInd = this.order.issues.findIndex(i => i.id === issue.id);
    if (issueInd === -1) {
      this.order = {
        ...this.order,
        issues: [...this.order.issues, issue]
      };
      return
    }

    this.order.issues[issueInd] = issue;
  }
}
