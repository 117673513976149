import { action, observable, runInAction } from 'mobx';
import { plainToClass } from 'class-transformer';
import { logger } from '@qlean/front-logger';

import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService';
import { ProspectDto, CalculatePriceDto, IError, DrycleaningMetaDto } from './ProspectStore.dto';
import { AddressDto, FioDto } from '../CustomerStore';
import { validateProspect } from 'src/utils/validation';

export default class ProspectStore {
  @observable isLoading: boolean = false;
  @observable errors: IError[] = [];
  @observable prospect?: ProspectDto;
  @observable disabledSlots: boolean = false;

  @action setErrors = (errors: IError[]) => {
    logger.debug('[ProspectStore:setErrors]', { errors });
    this.errors = errors;
  };

  @action setDisabledSlots = (state: boolean): void => {
    this.disabledSlots = state;
  }

  @action getProspectById = (args?: PlatformCRMWeb.IGetProspectByIdRequest) => {
    this.isLoading = true;
    const req = { ...args };
    logger.debug('[ProspectStore:getProspectById]', { args });

    GrpcService.PlatformCRMWeb.ProspectService.GetProspectById(req)
      .then((res) => {
        const { prospect } = res;
        logger.debug('[ProspectStore:getProspectById] ok', { res });

        runInAction(() => {
          this.prospect = plainToClass(ProspectDto, prospect);
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[ProspectStore::getProspectById]', e);
        this.isLoading = false;
      });
  };

  @action upsertProspect = (args: PlatformCRMWeb.IUpsertProspectRequest): Promise<IError[]> => {
    this.isLoading = true;

    const req = { ...args };
    logger.debug('[ProspectStore:upsert]', { args });

    return GrpcService.PlatformCRMWeb.ProspectService.Upsert(req)
      .then((res) => {
        const {
          customerId,
          totalPrice,
          totalDuration,
          isOvertime,
          price,
          qleanAddress,
          qleanOrderId,
          errors,
        } = res;
        logger.debug('[ProspectStore:upsert] ok', { res });
        const {
          serviceType,
          regionId,
          regularity,
          discount,
          startDate,
          paymentType,
          commentForSupport,
          commentForExecutor,
          phone,
          promocode,
          address,
          externalId,
          keysDelivery,
          keysPickup,
          drycleaningMeta,
          fio,
          numberOfPayments,
          leaveAtTheDoor,
        } = req;
        runInAction(() => {
          this.errors = validateProspect(
            errors.map((item) => ({
              type: item.type || 'system',
              message: item.message || 'fatal error',
            })),
            {
              regionId,
              address: plainToClass(AddressDto, address),
              startDate,
              paymentType,
              serviceType,
              externalId,
              price,
              regularity,
              drycleaningMeta: drycleaningMeta || undefined,
            },
          );

          if (this.prospect) {
            if (!this.prospect.promocode) {
              this.prospect.promocode = promocode || '';
            }

            this.prospect = {
              ...this.prospect,
              address: plainToClass(AddressDto, address) || this.prospect.address,
              serviceType: serviceType || this.prospect.serviceType,
              regionId: regionId || this.prospect.regionId,
              regularity: regularity || this.prospect.regularity,
              discount: discount || this.prospect.discount,
              startDate: startDate === '' ? startDate : startDate || this.prospect.startDate,
              paymentType: paymentType || this.prospect.paymentType,
              numberOfPayments: numberOfPayments || this.prospect.numberOfPayments,
              commentForSupport:
                commentForSupport === ''
                  ? commentForSupport
                  : commentForSupport || this.prospect.commentForSupport,
              commentForExecutor:
                commentForExecutor === ''
                  ? commentForExecutor
                  : commentForExecutor || this.prospect.commentForExecutor,
              promocode: promocode === '' ? promocode : promocode || this.prospect.promocode,
              person: {
                ...this.prospect.person,
                id: customerId,
              },
              phone: phone === '' ? phone : phone || this.prospect.phone,
              totalDuration,
              totalPrice,
              isOvertime,
              price: plainToClass(CalculatePriceDto, price),
              qleanAddress,
              qleanOrderId,
              drycleaningMeta:
                plainToClass(DrycleaningMetaDto, drycleaningMeta) || this.prospect.drycleaningMeta,
              externalId: externalId || this.prospect.externalId,
              keysPickup: plainToClass(AddressDto, keysPickup) || this.prospect.keysPickup,
              keysDelivery: plainToClass(AddressDto, keysDelivery) || this.prospect.keysDelivery,
              fio: plainToClass(FioDto, fio) || this.prospect.fio,
              leaveAtTheDoor: leaveAtTheDoor ?? this.prospect.leaveAtTheDoor,
            };
          }
          this.isLoading = false;
        });
        return [...this.errors];
      })
      .catch((e) => {
        logger.error('[ProspectStore::upsertProspect]', e);
        this.isLoading = false;
        return [...this.errors];
      });
  };
}
