import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { logger } from '@qlean/front-logger';
import { uniqBy } from 'lodash';
import { InView } from 'react-intersection-observer';
import { plainToClass } from 'class-transformer';

import { CommunicationDto, FindCommunicationsResponseDto } from 'src/store/CommunicationStore';
import { PlatformCRMWeb } from 'src/services/GrpcService/codegen/app_pb';
import { parseDate } from 'src/utils/formatters';
import { dictionary } from 'src/utils/dictionary';
import GrpcService from '../../services/GrpcService';

const infiniteScrollInitialPageSize = 30;
const infiniteScrollFollowingPageSize = 15;
const infiniteScrollBufferSize = 2;

export const ManagerCommunications = () => {
  const [managerCommunications, setManagerCommunications] = useState<CommunicationDto[]>([]);
  const [isLoadingManagerCommunications, setIsLoadingManagerCommunications] = useState(false);
  const [{ skip, take }, setPagination] = useState<PlatformCRMWeb.IPaginationOptions>({
    take: infiniteScrollInitialPageSize,
    skip: 0,
  });
  const tableBodyElRef = useRef<HTMLTableSectionElement | null>(null);
  const hasNextRef = useRef<boolean | null>(null);

  const getProperties = (
    communication: CommunicationDto
  ): { product: string; comment: string; postponedTo: string } => {
    const activeTask = communication.taskList
      .sort((a, b) => (new Date(a.task?.postponedTo as string).getTime()) - (new Date(b.task?.postponedTo as string).getTime()))
      .find(({ task }) => [PlatformCRMWeb.TaskState.RESCHEDULED].includes(task.state));
    const product = activeTask?.task.skill.name.split('-');
    const productText =
      dictionary.selectOptions.unionServiceTypes.find((pr) => pr.id === product?.[0])?.value ||
      'Ручной лид';

    return {
      product: productText,
      comment: activeTask?.task.comment || 'Без комментария',
      postponedTo: activeTask?.task?.postponedTo || '-',
    };
  };

  const findMoreManagerCommunications = async (
    args: PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest
  ): Promise<boolean> => {
    if (isLoadingManagerCommunications) {
      return false;
    }

    setIsLoadingManagerCommunications(true);
    logger.debug(`[ManagerCommunications.findMoreManagerCommunications] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.FindAvailableRescheduledCommunications(
        args
      );
      logger.debug(`[ManagerCommunications.findMoreManagerCommunications] result`, result);
      const { data, hasNext } = plainToClass(FindCommunicationsResponseDto, result);

      setIsLoadingManagerCommunications(false);
      setManagerCommunications((existed) => uniqBy([...existed, ...data], (item) => item.id));
      hasNextRef.current = hasNext;

      return true;
    } catch (err) {
      logger.error(`[ManagerCommunications.findMoreManagerCommunications] error`, err);
      setIsLoadingManagerCommunications(false);
    }
    return false;
  };

  useEffect(() => {
    findMoreManagerCommunications({
      pagination: {
        take,
        skip,
      },
    } as PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest);
  }, [skip, take]);

  const TableRow = ({ isObserved, children }: PropsWithChildren<{ isObserved: boolean }>) =>
    isObserved ? (
      <InView
        as="tr"
        onChange={(inView) => {
          const canLoadMore = hasNextRef.current !== false;

          if (inView && canLoadMore) {
            setPagination({
              skip: managerCommunications.length,
              take: infiniteScrollFollowingPageSize,
            });
          }
        }}
      >
        {children}
      </InView>
    ) : (
      <tr>{children}</tr>
    );

  return (
    <Card
      style={{ maxHeight: '100px', minHeight: 'calc(100% - 30px)', overflowY: 'auto' }}
      className="m-4 h-100"
    >
      <>
        {isLoadingManagerCommunications && (
          <div className="d-flex justify-content-center h-100 align-items-center">
            <Spinner animation="border" />
          </div>
        )}

        <Table>
          <thead>
            <tr>
              <th>Продукт</th>
              <th>Комментарий</th>
              <th>Дата отправления на перезвон</th>
              <th>Дата перезвона</th>
            </tr>
          </thead>
          <tbody ref={tableBodyElRef}>
            {managerCommunications &&
              managerCommunications.map((communication, i, array) => {
                const { product, comment, postponedTo } = getProperties(communication);
                const observedIndex = array.length - infiniteScrollBufferSize;

                return (
                  <TableRow key={communication.id} isObserved={i === observedIndex}>
                    <th>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/communication/${communication.id}`}
                      >
                        {product}
                      </a>
                    </th>
                    <td>{comment}</td>
                    <td>{parseDate(communication.updatedAt)}</td>
                    <td>{parseDate(postponedTo)}</td>
                  </TableRow>
                );
              })}
          </tbody>
        </Table>
      </>
    </Card>
  );
};
