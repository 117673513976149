import React from 'react';
import { Form, InputGroup, Button /*, DropdownButton, Dropdown*/ } from 'react-bootstrap';
import { Field } from 'react-final-form';
import formatString from 'format-string-by-pattern';
import { TFieldProp } from '../field.interface';
import { FieldLabel } from '../FieldLabel';
import { FieldError } from '../FieldError';
import { ERROR_MESSAGES, isValidPhone } from 'src/utils/validation';
import { CopyToClipboard } from 'react-copy-to-clipboard';

/**
 * TODO Так сделано только для примера, на деле это поле нужно делать по другому:
 * - слева должен быть DropDown с выбором страны
 * - маска поставляется автоматически в зависимости от выбранной страны
 * - маска не содержит код
 * - Хороший пример - форма ввода телефона в телеграмме или гугле
 */

interface State {
  showError: boolean;
}

export enum COUNTRY {
  RU = '+7',
}

export interface IMobilePhoneFieldProp {
  readonly country: COUNTRY;
  readonly hideCopyButton: boolean;
}

export class MobilePhoneField extends React.Component<IMobilePhoneFieldProp & TFieldProp<string>, State> {
  static defaultProps: Partial<IMobilePhoneFieldProp & TFieldProp<string>> = {
    country: COUNTRY.RU,
    hideCopyButton: false,
    isReadonly: false,
    isRequired: false,
  };

  constructor(props: IMobilePhoneFieldProp & TFieldProp<string>) {
    super(props);

    this.state = {
      showError: false,
    };
  }

  getMask() {
    switch (this.props.country) {
      case COUNTRY.RU:
      default:
        return '(999) 999-99-99';
    }
  }

  parser(value?: string): string {
    const { country } = this.props;
    const mask = this.getMask();

    if (typeof value === 'string') {
      // TODO Тут можно добавить больше вариантов парсинга
      const countryRegExp = new RegExp('^\\' + country.replace('+', '+?(') + '|8)|([^\\d])', 'g');
      value = value.replace(countryRegExp, '');
    }

    return formatString(mask)(value);
  }

  private onFocus = () => {
    this.setState({
      showError: false,
    });
  };

  private onBlur = (value: string) => {
    this.setState({
      showError: !isValidPhone(value),
    });
  };

  componentDidUpdate(prevProps: IMobilePhoneFieldProp & TFieldProp<string>) {
    const { errors } = this.props;
    if (prevProps.errors !== errors) {
      this.setState({
        showError: Boolean(errors),
      });
    }
  }

  render() {
    const { name, isRequired, isReadonly, initialValue, placeholder, label, errors, disabled } = this.props;

    function getValue(val: string) {
      val = val.replace(/[^\d]+/g, '');
      return `7${val}`;
    }

    return (
      <Field
        name={name}
        parse={this.parser.bind(this)}
        initialValue={this.parser(initialValue)}
        render={({ input }) => {
          return (
            <Form.Group controlId={name}>
              <FieldLabel {...label} isRequired={isRequired} />
              <InputGroup>
                <InputGroup.Prepend>
                  <Button disabled variant="secondary" style={{ zIndex: 0 }}>
                    {this.props.country}
                  </Button>
                </InputGroup.Prepend>
                {/*<DropdownButton id={name} as={ InputGroup.Prepend } variant='secondary' title={ this.props.country }>*/}
                {/*<Dropdown.Item>{ COUNTRY.RU }</Dropdown.Item>*/}
                {/*</DropdownButton>*/}
                <Form.Control
                  {...input}
                  placeholder={placeholder || this.getMask()}
                  readOnly={isReadonly}
                  onFocus={this.onFocus}
                  onBlur={() => this.onBlur(input.value)}
                  isInvalid={this.state.showError}
                  disabled={!!disabled}
                />
                {!this.props.hideCopyButton && (
                  <CopyToClipboard text={getValue(input.value)}>
                    <Button variant="primary">копировать</Button>
                  </CopyToClipboard>
                )}
                <FieldError error={errors ? `${errors}` : ERROR_MESSAGES.PHONE || ''} />
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    );
  }
}
