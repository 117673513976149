import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { Badge, Card, Collapse, ListGroup } from 'react-bootstrap';

import { CommunicationDto } from 'src/store/CommunicationStore';
import { parseDate } from 'src/utils/formatters';
import { dictionary } from 'src/utils/dictionary';
import InfoPanel, { IInfoPanelRow } from 'src/components/InfoPanel';
import { PlatformCRMWeb } from 'src/services/GrpcService';

import { ReactComponent as SuccessLogo } from 'src/features/Communication/CrossellTabs/assets/success.svg';
import { ReactComponent as CancelledLogo } from 'src/features/Communication/CrossellTabs/assets/cancelled.svg';

interface IProps {
  communication: CommunicationDto;
  expand: (e, pane) => void;
  accordionValue: number;
  stateValue?: number;
  isCurrentCommunication?: boolean;
}

const CommunicationInHistory = observer(
  ({ communication, expand, accordionValue, stateValue, isCurrentCommunication }: IProps) => {
    const [expandedTask, setExpandedTask] = useState(undefined);

    const expandAccordion = (e, pane) => {
      e.preventDefault();
      const value = expandedTask === pane ? null : pane;
      setExpandedTask(value);
    };

    const getTitle = () => {
      return (
        <div>
          <Badge
            className="py-1 px-2 mr-2"
            variant={isCurrentCommunication ? 'success' : 'secondary'}
          >
            {
              dictionary.selectOptions.communicationStatus.find(
                (status) => status.id === communication.state
              )?.value
            }
          </Badge>
          <span>Обновлена: {parseDate(communication.updatedAt, 'dateInTime')}</span>
        </div>
      );
    };

    const getServiceTypeText = (skill: string) => {
      const product = skill?.substr(0, skill.indexOf('-'));
      return (
        dictionary.selectOptions.unionServiceTypes.find((serviceType) => serviceType.id === product)
          ?.value || 'Ручной лид'
      );
    };

    const getClassNameForTask = (state: PlatformCRMWeb.TaskState): string => {
      if (state === PlatformCRMWeb.TaskState.CANCELLED) {
        return 'text-danger';
      }
      if (state === PlatformCRMWeb.TaskState.PROCEED) {
        return 'text-success';
      }
      return '';
    };

    const rows: IInfoPanelRow[] = [
      {
        key: '№ коммуникации',
        value: communication.registrationNumber.toString(),
        link: `/communication/${communication.id}`,
      },
      { key: 'Дата обновления', value: parseDate(communication.updatedAt) },
      { key: 'Дата создания', value: parseDate(communication.createdAt) },
      { key: 'Владелец', value: communication?.owner?.name },
    ];

    return (
      <Card className="border-0">
        <Card.Header
          as="h6"
          className="font-weight-normal table-hover cursor-pointer"
          onClick={(e) => expand(e, accordionValue)}
        >
          <a
            className="text-body d-flex justify-content-between"
            aria-expanded={stateValue === accordionValue}
          >
            {getTitle()}
            <div className="collapse-icon"></div>
          </a>
          <div className="mt-2">
            {communication.taskList.map(({ task }) => {
              return (
                <div
                  className={`mr-3 ${getClassNameForTask(task.state)}`}
                  key={task.id}
                  style={{ fontSize: '12px' }}
                >
                  {task.state === PlatformCRMWeb.TaskState.PROCEED && (
                    <SuccessLogo className="mr-1" width="14px" height="17px" />
                  )}
                  {task.state === PlatformCRMWeb.TaskState.CANCELLED && (
                    <CancelledLogo className="mr-1" width="14px" height="17px" />
                  )}
                  {getServiceTypeText(task.skill.name)}
                </div>
              );
            })}
          </div>
        </Card.Header>
        <Collapse in={stateValue === accordionValue}>
          <div>
            <InfoPanel rows={rows} />
            <div
              style={{
                borderBottom: '1px solid #e8e8e9',
                borderTop: '1px solid #e8e8e9',
                backgroundColor: '#FAFAFB',
              }}
            >
              {communication.taskList.map(({ task }) => {
                const rows: IInfoPanelRow[] = [
                  { key: 'Скилл', value: task.skill.name },
                  {
                    key: 'Дата создания',
                    value: parseDate(task.createdAt, 'dateInTime'),
                  },
                  {
                    key: 'Статус',
                    value: dictionary.selectOptions.taskStatus.find(
                      (status) => status.id === task.state
                    )?.value,
                  },
                  { keyType: 'small', key: 'Причина', value: task.cancellationReason?.title },
                ];
                return (
                  <div key={task.id}>
                    <ListGroup variant="flush">
                      <ListGroup.Item
                        onClick={(e) => expandAccordion(e, task.id)}
                        aria-expanded={expandedTask === task.id}
                        className="d-flex justify-content-between align-items-center text-body cursor-pointer"
                      >
                        <div className={getClassNameForTask(task.state)}>
                          {task.state === PlatformCRMWeb.TaskState.PROCEED && (
                            <SuccessLogo className="mr-1" width="20px" height="20px" />
                          )}
                          {task.state === PlatformCRMWeb.TaskState.CANCELLED && (
                            <CancelledLogo className="mr-1" width="20px" height="20px" />
                          )}
                          {getServiceTypeText(task.skill.name)}
                        </div>
                        <div className="collapse-icon"></div>
                      </ListGroup.Item>
                    </ListGroup>
                    <Collapse in={expandedTask === task.id}>
                      <div>
                        <InfoPanel rows={rows} />
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            </div>
          </div>
        </Collapse>
      </Card>
    );
  }
);

export default CommunicationInHistory;
